<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionAdd">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionAdd">
      <b-col>
        <b-container>
          <b-row class="mb-4" v-if="firststep">
            <b-col>
              <h4>Adding new event</h4>

              <div class="mb-2"><strong>Event title</strong></div>
              <b-form-input   id="titleInput"
                                v-model="row.name"
                                placeholder="Event name"
                                required
                                class="mb-2">
              </b-form-input>

                <div class="mb-2"><strong>Event date</strong></div>
              <b-form-datepicker id="date-picker"
                v-model="row.date"
                required>
              </b-form-datepicker>

              <div class="mb-2"><strong>Comments (markdown)</strong></div>
              <b-form-textarea id="detailsInput"
                                v-model="row.comments"
                                placeholder="Comments"
                                required
                                rows=10
                                class="mb-2">
              </b-form-textarea>

              <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="preview">Preview</b-button>
              <b-button variant="outline-primary" size="sm" :to="{ name: 'Events' }">Cancel</b-button>

            </b-col>
          </b-row>

          <b-row class="mb-4" v-if="previewstep">
            <b-col>
              <h4>{{row.name}}</h4>
              <div>{{row.date}}</div>
              <div><markdown :content="row.comments" /></div>
              <div>created by: {{row.username}}</div>
              <span v-if="!saving">
                <b-button v-if="validate" class="mr-2" variant="primary" size="sm" v-on:click="save">Save</b-button>
                <b-button variant="outline-primary" size="sm" v-on:click="edit">Edit</b-button>
              </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="finalstep">
            <b-col>
              <h4>Created: {{row.name}}</h4>
              <div>{{row.date}}</div>
              <div><markdown :content="row.comments" /></div>
              <div>created by: {{row.createdBy}}</div>
              <b-button variant="primary" size="lg" :to="{ name: 'EventEdit', params: { id: row.id }}">Continue to edit details</b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '../libs/accesscontrol'
import Markdown from '@/components/Markdown.vue'

export default {
  name: 'EventAdd',
  components: {
    Markdown
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validate: function () {
      return this.row.name && this.row.comments
    }
  },
  created: function () {
    this.permissionAdd = ac.can(this.user.acgroups).createAny('event').granted
    this.row.username = this.user.username
  },
  data () {
    return {
      firststep: true,
      finalstep: false,
      permissionAddRisk: false,
      previewstep: false,
      saving: false,
      rendered: {
        comments: null
      },
      row: {
        date: null,
        name: null,
        comments: null,
        username: null
      }
    }
  },
  methods: {
    edit: function () {
      this.firststep = true
      this.previewstep = false
      this.finalstep = false
    },
    preview: function () {
      this.firststep = false
      this.finalstep = false
      this.previewstep = true
    },
    save: async function () {
      this.$logger.debug(`save start`)
      this.finalstep = false
      this.previewstep = false
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/event-simple`
        let params = {
          body: {
            event: this.row
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.row = response
        this.finalstep = true
      } catch (e) {
        this.$logger.warn('row saving error: ' + e)
      }
      this.saving = false
    }
  }
}
</script>

<style>
</style>
